<template>
<!-- 协会 -->
    <div>
        <div class="posit-left">
            <listassociation></listassociation>
        </div>
        <div class="posit-right">
            <assrighttop style="margin-bottom:0.18rem"></assrighttop>
            <photoservice></photoservice>
        </div>
        <asspopout v-if="$store.state.asspopout"></asspopout>
    </div>    
</template>

<script>

export default {
    components: {
        listassociation: () => import('../charts/association/listassociation.vue'),
        assrighttop: () => import('../charts/association/assrighttop.vue'),
        photoservice: () => import('../charts/association/photoservice.vue'),
        asspopout: () => import('../charts/associationpopout/asspopout.vue'),       // 弹框
    }
}
</script>

<style scoped>

</style>